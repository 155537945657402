@media print {
  @page {
    size: a4 portrait;
    margin: 80px;
  }

  .download-svg {
    display: none;
  }

  * {
    box-shadow: unset !important;
  }
}
